@import "../../styles/common";

.info-circle {
  width: 47px;
  height: 47px;
  border: solid 2px #f06c00;
  background-color: #ffffff;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}

.info-label {
  font-family: $ln-font-family-brand;
  font-size: 2.5rem;
  font-weight: bold;
  color: #f06c00;
  display: inline-block;
  line-height: 32px;
  margin-top: 5px;
}
