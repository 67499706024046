@import "../../styles/common";

.BlockedButtons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 599px) {
    justify-content: center;
  }

  &--button {
    font-size: 16px;
    margin: $ln-space;
    width: 14rem;
  }
}
