@import "../styles/common";

.progress {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 30px;
  margin-bottom: $ln-space-x3;

  @media (max-width: 599px) {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.progress .circle {
  width: 43px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid $ln-color-beta-light;
  vertical-align: top;
}

.progress .bar {
  min-width: 30px;
  flex-grow: 0;
  flex-shrink: 1;
  border: 2px none $ln-color-beta-light;
  border-bottom-style: dotted;
  align-self: center;
  flex-basis: 115px;
}

.progress .circle .label {
  font-family: $ln-font-family-brand;
  display: inline-block;
  margin-top: 2px;
  height: 21px;
  font-size: 16px;
  line-height: 36px;
}

.progress .circle .title {
  font-family: $ln-font-family-brand;
  color: $ln-color-text;
  display: block;
  line-height: 18px;
  font-size: 16px;
  padding-top: $ln-space;

  @media (min-width: 720px) {
    white-space: nowrap;
    margin: 10px -28px 0;
  }

  @media (max-width: 599px) {
    font-size: 14px;
    margin: 10px -20px 0;
  }
}

.progress .circle.active {
  border: 2px solid $ln-color-alpha;
}

.progress .circle.active {
  .label {
    color: $ln-color-alpha;
    font-weight: 800;
    font-size: 18pt; // Increase active label to 18pt - this ensures accessibility contrast ratio is met
    margin-top: 1px;
  }
  // Amend margin-top for active element to compensate for increased font-size
  // compared to non-active step indicator
  .title {
    @media (min-width: 720px) {
      white-space: nowrap;
      margin: 8px -28px 0;
    }

    @media (max-width: 599px) {
      font-size: 14px;
      margin: 8px -20px 0;
    }
  }
}
