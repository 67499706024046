@import "../../styles/common";

.account-list-selector {
  margin: 24px 0px 0px 0px !important;
  font-size: 16px;
  font-weight: normal;

  .ln-c-label {
    margin-bottom: 26px;
  }
}
