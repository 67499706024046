@import "../../styles/common";

.SBForm {
  max-width: 480px;
  margin: auto;
  margin-top: 2rem;

  &__error {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #d10000;
  }

  &__error-column {
    padding-left: 5px;
    padding-right: 5px;
  }

  &__error-indicator {
    font-weight: bold;
  }

  &__fields {
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 5px;
  }

  &__submit {
    margin-top: 15px;
  }

  &--error &__fields {
    border: 1px solid #d70000;
  }
}
