@import "../../styles/common";

.OBModal {
  text-align: center;
  padding: 24px;

  .ln-c-modal__body {
    @media (min-width: 600px) {
      max-width: 450px;
    }

    @media (max-width: 599px) {
      max-width: 300px;
    }

    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
  }

  &__icon-wrapper {
    @media (max-width: 599px) {
      display: none;
    }

    font-size: 2.5rem;
    font-weight: bold;
    color: #f06c00;
    display: inline-block;
    line-height: 35px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    &--wrapper {
      @media (max-width: 599px) {
        margin-top: 30px;
      }

      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      margin: 8px;
    }

    &--h1 {
      font-size: 26px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;
      letter-spacing: normal;
      margin: 0px;

      &.text-center {
        text-align: center;
      }
    }
  }

  &__body {
    margin-top: 24px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;

    &--list {
      text-align: left;
      margin: 8px;

      p {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-bottom: 0.5rem;
      }

      ul {
        @media (min-width: 600px) {
          max-width: 400px;
          margin-left: 30px;
        }
      }

      li {
        @media (max-width: 599px) {
          margin: 0 8px 20px 0;
        }

        @media (min-width: 600px) {
          margin: 0 0 20px 0;
        }
      }
    }
  }

  &__footer {
    margin-top: $ln-space-x3;

    p {
      margin-left: 8px;
    }
  }
}
