@import "../../styles/common.scss";

.sb-submit {
  .is-disabled {
    background-color: $ln-color-alpha !important;
  }

  button > span {
    padding-left: 0.5rem;
  }
}
