@import "../../../styles/common.scss";

#sb-remember-me {
  .ln-c-form-option__label {
    margin-right: -2.8rem;
    padding-right: 2.8rem;
  }
  .is-open {
    display: inline;
    > div,
    .ln-c-tooltip__icon,
    .ln-c-icon {
      display: inline;
    }
  }
}
