@import "../../styles/common";

.OBFscsBanner {
  border-top: 4px solid $ln-color-grey-v-light;
  border-bottom: 4px solid $ln-color-grey-v-light;
  margin-top: 20px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-family: $ln-font-family-brand;

  &__text {
    font-size: 14px;
    margin-left: 45px;

    @media (max-width: 599px) {
      width: 150px;
    }
  }

  @media (min-width: 599px) {
    width: 450px;
  }

  &__image {
    @media (max-width: 599px) {
      max-width: 32%;
    }

    max-width: 25%;

    padding: 0 20px;
  }
}
