.sb-one-time-passcode.has-error {
  display: block;
}

.sb-one-time-passcode {
  .sb-warning {
    font-size: 0.825rem;
    white-space: nowrap;

    span {
      margin-left: 0.25rem;
    }
  }

  .ln-c-text-input {
    margin-top: 1rem;
  }
}
