@import "../../styles/common";

.sb-error-page {
  h2 {
    color: $ln-color-orange;
  }

  .ln-o-grid__item {
    padding-left: 0.5rem;
  }

  .ln-o-grid__item:first-child {
    padding-left: 0;
    padding-right: 0.5rem;
  }

  @include ln-respond-to("max-ss") {
    .ln-o-grid__item {
      padding-left: 0;
    }
    .ln-o-grid__item:first-child {
      padding-right: 0;
    }
  }
}
