@import "../../styles/common";

.OBinputLabel {
  font-family: $ln-font-family-brand;
  font-size: 16px;
  line-height: 1.22;
  color: #4c4c4c;
  padding: 12px 0;
  display: block;
}
