@import "../../styles/common";

.Banner {
  max-width: 479px;
  border: 1px solid #d6d6d6;
  margin: 0 auto $ln-space;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;

  &__column {
    padding-left: 5px;
    padding-right: 5px;
  }
}