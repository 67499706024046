.ClickableLink {
  display: block;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b9005c !important;

  &:focus{
    box-shadow: 0 0 0 3px rgba(0, 169, 207, 0.5);
  }
}
