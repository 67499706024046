@import "../../styles/common";

.OBForm {
  @include single-column-layout(); 

  &__error {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $ln-color-grey-dark;
  }

  &__error-column {
    padding-left: 5px;
    padding-right: 5px;
  }

  &__fields {
    border: 1px solid transparent;
    border-radius: 5px;
  }

  &__submit {
    margin-top: $ln-space-x4;
  }

  &--error &__fields {
    border: 1px solid #d70000;
    padding: 15px;

    // Ensures that none TextInput does not
    // show invalid styling when form fails
    // this matches other form elements on page
    .OBTextInput {
      input {
        &:invalid {
          box-shadow: 0 0 0 3px transparent;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
