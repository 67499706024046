@import "../../../styles/common.scss";

.register-body-1 {
    margin-top: 30px;
}
.error-circle {
    font-size: 26px;
    line-height: 32px;
    margin-right: 5px; 
}
.ln-c-button--filled {
    background-color: $ln-color-orange !important;
    color: $ln-color-white !important;
}


.sb-modal-buttons {
    display: flex;
    flex-direction: column;
    %modal-button {
      flex-grow: 0;
      flex-basis: 50%;
    }
    .sb-modal-buttons__exit, .sb-modal-buttons__contact_us {
      @extend %modal-button;
    }
    @include ln-respond-to('max-sm') {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      %modal-button {
        margin-bottom: 10px;
      }
      .sb-modal-buttons__exit {
        margin-right: 0rem;
      }
    }
}
