@import "../../styles/_common.scss";

.SBAuthenticationPage {
  @include single-column-layout();

  &__mobile-prompt {
    padding-top: $ln-space-x3;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #4c4c4c;
  }

  &__digit-request {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #707070;
  }

  &__mobile-label-tooltip-wrapper {
    label {
      display: inline-block;
    }
  }

  &__mobile-container {
    @include pin-input-fields-flex-width();

    &--phone-inputs {
      @include pin-input-fields-flex-width();
      flex-grow: 0.25;
    }
  }

  &__digit {
    text-align: center;
  }

  &__digit-input {
    max-width: 4rem;
    text-align: center !important;
    font-family: Arial !important;
    letter-spacing: 0.44px !important;
    color: #4c4c4c !important;
  }

  &__stay-safe-container {
    margin-top: $ln-space-x2;
  }
  &__stay-safe-copy {
    margin-bottom: $ln-space;
  }
}
