@import "../../styles/common";

.option-border-bottom {
  border-bottom: 1px solid $ln-color-grey-light;
}

.option-border-bottom:last-child {
  border-bottom: none;
}

#confirm-access {
  &__container {
    @include single-column-layout();
  }

  &__intro-section {
    padding-bottom: 0px;
  }

  &__intro-card {
    padding-top: $ln-space-x4;
    padding-bottom: $ln-space;
  }

  &__title {
    font-size: 32px;
    font-weight: 800;
    line-height: 1.18;

    @media (max-width: 720px) {
      font-size: 26px;
      line-height: 1.23;
    }
  }

  &__info {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__withdrawal-message {
    font-size: 16px;
    font-weight: bold;
  }

  &__loading {
    @include single-column-layout();

    @media (max-width: 720px) {
      margin-top: $ln-space-x2;
    }

    .ln-c-progress-spinner {
      margin-top: 40px;

      @media (max-width: 720px) {
        @include ln-font($ln-font-h2);
        height: 48px;
        width: 48px;
        margin-top: $ln-space-x2;
      }
    }

    h2 {
      margin-top: $ln-space-x3;

      @media (max-width: 720px) {
        margin-top: 12px;
      }
    }
  }
}

#permission-list-container {
  background-color: $ln-color-grey-v-light !important;
  border: 1px solid $ln-color-grey-light;
  margin: 0px 24px;
  padding: $ln-space-x3;

  h3 {
    font-size: 22px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.37px;
  }

  @media only screen and (max-width: 599px) {
    margin: 0px $ln-space-x2;
  }

  ul {
    margin-left: $ln-space-x3;
    font-size: 16px;
    font-weight: normal;
  }
}

.account-list-label {
  background: transparent;
}

#account-card {
  &__margin {
    margin-top: $ln-space-x3;
  }
}

legend[for="account-list-selector"] {
  @include ln-font($ln-font-body);
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
