.sb-btn-wrapper {
  max-width: 12rem;
  min-width: 12rem;

  @media (max-width: 599px) {
    min-width: 9rem;
    max-width: 9rem;
  }

  @media (max-width: 320px) {
    min-width: 7.5rem;
    max-width: 7.5rem;
  }
}

.button-style {
  padding: 0px;
}

.button-text {
  font-size: 18px;
  font-weight: 800;
  text-align: center;

  @media (max-width: 599px) {
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }

  @media (max-width: 320px) {
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }
}