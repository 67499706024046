@import "../../styles/common";

$authentication-page-spacing: 12px;

.OBAuthenticationPage {
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: -$authentication-page-spacing;
  margin-right: -$authentication-page-spacing;

  @media (min-width: 600px) {
    display: flex;
  }

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }

  &__wrapper {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: #{map-get($ln-container-sizes, "def") / 2}px;
    margin: 0 auto;
    padding: 20px $authentication-page-spacing;

    #form-username-passwordValidation {
      margin-bottom: 1.5rem;
    }

    &__input {
      padding-right: 0 !important;
    }

    //overrides for luna styling
    .ln-c-form-group {
      margin-bottom: 0.5rem !important;
    }

    //override for react tooltip to increase mobile width
    #mobileTooltip.OBTooltip {
      @media (min-width: 600px) {
        max-width: 321px !important;
      }
    }

    .checkBoxWrapper {
      display: inline-flex;

      //overrides for luna styling
      .ln-c-form-group {
        padding-right: $ln-space-x2 !important;
      }

      .ln-c-form-option__label {
        width: 200px;
      }

      .RememberMe--tooltip {
        margin-left: -3.1rem !important;
      }
    }

    .tooltip-margin-left {
      margin-left: 0.5rem !important;
    }

    .tooltip-margin-top {
      margin-top: 0.85rem !important;
    }

    .digit {
      text-align: center;
    }

    .digit-input {
      max-width: 4rem;
      text-align: center !important;
      font-family: Arial !important;
      letter-spacing: 0.44px !important;
      color: #4c4c4c !important;
      height: 48px;
    }

    .label-spacing {
      margin-top: $ln-space-x2;
    }
  }

  &__modal-helpers {
    margin-top: $ln-space-x2;

    #textButtonLink,
    #registerNumber {
      text-align: left;
      padding: 0;
      background-color: $ln-color-white;
    }
  }

  .links {
    min-height: $ln-space-x4;
  }
}
