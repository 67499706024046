@import "../styles/common";

.FinalPage,
.confirmation {
  @include single-column-layout();

  @media (max-width: 720px) {
    margin-top: 16px;
  }

  &__header {
    @include ln-font($ln-font-h2);
    margin-top: 24px;

    @media (max-width: 720px) {
      margin-top: 12px;
    }

    &--error {
      font-size: 22px;
    }
  }

  p {
    margin-top: 40px;
    @media (max-width: 720px) {
      margin-top: 8px;
    }
  }

  .ln-c-progress-spinner {
    margin-top: 40px;

    @media (max-width: 720px) {
      @include ln-font($ln-font-h2);
      height: 48px;
      width: 48px;
      margin-top: 16px;
    }
  }
}
