@import "../../styles/common";

.ln-c-field-info--error::before {
  background-color: $ln-color-red !important;
}
.error-circle {
  margin-right: 5px;
}
.timeout-onscreen {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
  max-width: 480px;
  font-family: Arial;
  color: #d10000;

  &__column {
    padding-left: 5px;
    padding-right: 5px;
  }

  &__text {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d10000;
  }

  &__openBanking {
    margin-top: $ln-space;
    display: flex;
    padding: $ln-space;
    margin: 0 auto;
  }
}

.ln-o-grid {
  margin-left: 0px;
  height: 100%;
}

.scroll-area {
  margin: 40px 24px 24px 24px;
}

.grid-item {
  padding-left: 0px;
}

#timeout-modal__header {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  color: #f06c00;
  margin: auto;
}

.countdown-timer {
  width: 201px;
  height: 112px;
  font-family: MaryAnn;
  font-size: 96px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-left: auto;
  margin-right: auto;
}
.body-seconds {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  position: relative;
  top: -50px;
}
.sb-modal-nav-buttons {
  display: flex;
  flex-direction: column;
}
