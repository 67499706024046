@import "../../styles/common";

.OBLinkButton {
  font-size: $ln-browser-font-size-default * 1px;
  margin: 0.5rem;
  width: 14rem;

  &__new-window-icon {
    margin-right: $ln-space/3;
  }
}
