@import "../styles/common";

.title-icon {
  height: 35px !important;
  width: 35px !important;
  margin-bottom: $ln-space;
}

.title-text {
  margin-bottom: $ln-space-x3;
  font-size: 22px;
  font-weight: 800;
  line-height: 1.18;
}
