@import "../../../styles/common";

.FscsBanner {
  border-top: 4px solid $ln-color-grey-v-light;
  border-bottom: 4px solid $ln-color-grey-v-light;
  margin-top: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  display: flex;
  align-items: center;

  &__image {
    max-width: 33%;
    padding: 0 20px;
  }

  &__text,
  &__link {
    font-size: 0.85rem;
  }
}
