@import "../../styles/common";

.footer {
  margin-top: 56px;

  &__new-window-icon {
    margin-right: $ln-space/3;
  }
}

.footer-text {
  color: $ln-color-grey-light;
  font-family: $ln-font-family-brand;
  font-size: 11px;
  margin-bottom: 0;
  margin-top: 10px;
}

.footer-link {
  font-family: $ln-font-family-brand;
  font-size: 11px;
}