.sb-password.has-error {
  display: block;
}
@import "../../../styles/common";

.sb-password {
  .sb-link-list {
    margin-top: 0.5rem;
  }

  .ln-c-input-group {
    border: 1px solid $ln-color-grey;
    input {
      border: none;
    }
  }

  &.has-error.ln-c-form-group .ln-c-input-group {
    border-color: $ln-color-red;
    border-left-width: 4px;
  }
}
