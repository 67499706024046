@import "../../styles/common";

.OBInlineText {
  &__error {
    display: flex;
    margin-top: $ln-space;
    font-weight: bold;

    &--column {
      &::before {
        $size: 20px;

        background: $ln-color-error;
        border-radius: 50%;
        color: $ln-color-white;
        content: "!";
        display: inline-block;
        font-family: $ln-font-family-brand;
        height: $size;
        line-height: $size;
        margin-right: $ln-space;
        text-align: center;
        width: $size;
      }
    }
  }
}
