@import "../styles/common";

.ln-o-page__header {
  .sb-auth-container {
    max-width: 480px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;

    :first-child,
    :last-child {
      flex: 1;
    }
  }

  .ln-c-button {
    width: fit-content;
    text-align: left;
    padding: 0;
    padding-right: 0.5rem;
    background-color: unset !important;
  }

  @media (max-width: 430px) {
    svg[label="Sainsbury's Bank"] {
      width: 171px;
    }
  }
}
