@import "../../../styles/common.scss";

.sb-link-list {
  .ln-c-link,
  .ln-c-button--link {
    font-size: 0.825rem;
    font-family: $ln-font-family-brand;
    color: $ln-color-orange;
    letter-spacing: 0.317333px;
  }

  .ln-o-bare-list__item:not(:last-child) {
    padding-bottom: 0;
  }

  .ln-c-button--link {
    padding: 0;
    min-height: $ln-space;
    min-width: $ln-space;
    line-height: $ln-space-x2;
  }

  .ln-c-button--link:hover,
  .ln-c-button--link:focus {
    background-color: $ln-color-light-highlight !important;
    color: $ln-color-orange !important;
    text-decoration: underline;
  }

  .ln-c-button__underline::after {
    height: 0;
  }

  .ln-c-tooltip__icon {
    min-height: $ln-space;
    min-width: $ln-space;
    line-height: $ln-space;
    margin-left: $ln-space;
  }
}
