@import "../styles/common";

.ln-o-page__header {
  h1 {
    margin: 0;
    padding-top: 10px;
  }

  hr {
    align-self: center;
    width: 36rem;
    max-height: 1px;
    padding-top: 24px;
  }

  .divider-line {
    display: flex;
    justify-content: center;
    max-height: 1px;
  }
}
