@import "../../styles/common";

.OBln-c-tooltip__message {
  font-weight: normal;
  font-family: Arial;
  border: 1px solid $ln-color-orange;;
  line-height: 1.5;
  font-size: 16px;
  background-color: $ln-color-white;
  color: $ln-color-grey-dark;
  border-radius: $ln-border-radius;
  opacity: 0;
  visibility: hidden;
  padding: 8px 21px;
  transition-property: padding-top, padding-bottom, visibility, opacity,
  position, max-height, margin-top;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  @media (max-width: 720px) {
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;

    .is-open & {
      padding: $ln-space;
    }
  }

  .is-open & {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 999;
    max-height: 100vh;
    margin-top: $ln-space;
    width: 200px;

    @media (max-width: 599px) {
      max-height: none;
      margin-top: 0;
    }
  }
}
