@import "../../styles/common";

.OBTooltipIndicator {
  line-height: 0 !important;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #4c4c4c;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: bold;
  font-size: 16px;
  font-family: $ln-font-family-brand;
}