@import '@jsluna/style/scss/foundation';

.dislike-icon {
    width: 38px;
    height: 38px;
}
.icon-wrapper {
    text-align: center;
}
.sb-modal-buttons-max-otp-attempts {
  display: flex;
  flex-direction: column;
  %modal-button {
    flex-grow: 0;
    flex-basis: 50%;
    margin-bottom: 10px !important;
  }
  .sb-modal-buttons__exit, .sb-modal-buttons__contact_us {
    @extend %modal-button;
  }
  .ln-c-button--outlined.ln-c-button--dark {
    border-color: #f06c00;
    color: #f06c00;
  }
  @include ln-respond-to('max-sm') {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    %modal-button {
      margin-bottom: 10px;
    }
    .sb-modal-buttons__exit {
      margin-right: 0rem;
    }
  }
}
.check-list {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  .ln-c-icon {
    flex: 1;
    max-width: 32px;
    height: 32px;
    margin-right: 0.2em;
    margin-top: -5px;
  }
  .bus-list__item {
    flex: 3;
  }
}