@import "../../styles/common";

.OBSecurityCheck {
  .formHeading {
    max-width: 480px;
    padding-top: 1rem;

    @media (min-width: 720px) {
      margin: 0 auto;
    }
  }

  // Ensures OTP form input is correct width, but error message spans across
  // width of form group
  .input-width-small {
    input {
      @include ln-font($ln-font-body);
      width: 7rem;
      margin-bottom: 0px;
      height: 48px;
    }
  }

  &__request-otp {
    display: inline-block;
    height: 24px;
    font-family: MaryAnn;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.33;
    color: $ln-color-grey-dark;
    margin-bottom: $ln-space-x2;
    cursor: pointer;
  }

  &__passcode {
    &--label {
      @include ln-font($ln-font-body);
      margin-bottom: $ln-space-x3;
      padding: 0px;
    }
  }

  &__passcode-help {
    margin: $ln-space 0px;
  }

  .OBPinEntry {
    &__pin-input-wrapper, &__pin-input-fields {
      @include pin-input-fields-flex-width();
    }

    &__pin-digit-label {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      font-weight: 800;
      line-height: 1.33;
      letter-spacing: normal;
      color: #4c4c4c;
    }

    &__pin-digit-input {
      max-width: 4rem;
      text-align: center !important;
      height: 48px;
    }

    &__passcode {
      width: 120px;
      font-family: Arial !important;
      letter-spacing: 0.44px !important;
      color: #4c4c4c !important;
    }
  }

  label[for^="pin-digit"] {
    font-weight: bold;
    font-size: 18px;
  }

  &__forgotten-details {
    margin-top: $ln-space-x3;
  }
}

.inline-select-form {
  @include single-column-layout();

  .inline-select-form-container {
    display: flex;
    justify-content: space-between;
  }

  .inline-select-form-digit-wrapper {
    @include pin-input-fields-flex-width();


    &--digits {
      @include pin-input-fields-flex-width();
    }

  }

  .ln-c-form-group {
    display: flex;
    flex-direction: column;
    padding-right: 1em;
  }

  .ln-c-form-group:last-child {
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }

  .ln-c-field-info--error::before {
    background-color: $ln-color-red;
  }

  select {
    min-width: 100%;
  }
}
