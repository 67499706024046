@import "../../styles/common";

$tooltip-color: $ln-color-orange;
$ln-tooltip-arrow-size: 7px !default;
$ln-tooltip-background: $ln-color-orange !default;
$ln-tooltip-hover-background: $ln-color-grey-light !default;

.OBln-c-tooltip {
  // Known bug for Safari mobile where click events aren't permitted on div/span
  // Use known workaround cursor: pointer to resolve this
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event#Safari_Mobile
  cursor: pointer;
  display: inline-block;
  position: relative !important;
}

.OBln-c-tooltip__arrow {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 0;

  &.OBln-c-tooltip__arrow--top,
  &.OBln-c-tooltip__arrow--top-end,
  &.OBln-c-tooltip__arrow--top-start {
    border-width: $ln-tooltip-arrow-size $ln-tooltip-arrow-size 0
      $ln-tooltip-arrow-size;
    border-color: $ln-tooltip-background transparent transparent transparent;
    bottom: $ln-tooltip-arrow-size * -1;
    left: 50%;
    right: 50%;
  }

  &.OBln-c-tooltip__arrow--bottom,
  &.OBln-c-tooltip__arrow--bottom-start,
  &.OBln-c-tooltip__arrow--bottom-end {
    border-width: 0 $ln-tooltip-arrow-size $ln-tooltip-arrow-size
      $ln-tooltip-arrow-size;
    border-color: transparent transparent $ln-tooltip-background transparent;
    top: $ln-tooltip-arrow-size * -1;
    left: 50%;
    right: 50%;
  }

  &.OBln-c-tooltip__arrow--right,
  &.OBln-c-tooltip__arrow--right-start,
  &.OBln-c-tooltip__arrow--right-end {
    border-width: $ln-tooltip-arrow-size $ln-tooltip-arrow-size
      $ln-tooltip-arrow-size 0;
    border-color: transparent $ln-tooltip-background transparent transparent;
    left: 0;
    top: 50%;
    margin-left: $ln-tooltip-arrow-size * -1;
  }

  &.OBln-c-tooltip__arrow--left,
  &.OBln-c-tooltip__arrow--left-start,
  &.OBln-c-tooltip__arrow--left-end {
    border-width: $ln-tooltip-arrow-size 0 $ln-tooltip-arrow-size
      $ln-tooltip-arrow-size;
    border-color: transparent transparent transparent $ln-tooltip-background;
    top: 50%;
    right: 0;
    margin-right: $ln-tooltip-arrow-size * -1;
  }
}