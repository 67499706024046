$ln-webpack: true;

$ln-container-sizes: (
  def: 688
);

@mixin single-column-layout {
  @media (min-width: 720px) {
    max-width: 480px;
  }

  margin: 0 auto;
}

@mixin pin-input-fields-flex-width {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  flex-grow: 0.5;
}

@import "~@jsluna/style/scss/settings";
@import "~@jsluna/style/scss/tools";
