@import "../../styles/common.scss";

.OBTextInput {
  margin: 0;
  padding: 0;

  &__error-message {
    color: red;
  }

  &__show-password {
    margin-left: -70px;
    display: inline-block;
    padding: 10px 16px;
    font-family: MaryAnn;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b9005c;
    background: transparent;
  }

  &__input {
    padding: 8px 16px 8px 16px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px #707070;
    margin: 0;
    vertical-align: top;
    font-family: MaryAnn;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.04px;
    color: #333333;

    // Mimic Luna's styling for focus on TextInputField
    &:not(:disabled):not([readonly]):focus {
      border-color: #e44f00;
      box-shadow: inset 0 0 0 1px $ln-color-orange-dark,
        0 0 0 3px rgba(0, 169, 207, 0.5);
    }

    &--has-error {
      border-color: red;
    }

    &--show-button {
      padding-right: 66px;
    }
  }
}
